import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';

function Content({
  animationDirection,
  animationRef,
  resetAnimaions,
  children,
  handleAnimationFinish,
  style,
}) {
  const contentAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    onRest: handleAnimationFinish,
    from: { opacity: animationDirection === 'mount' ? 0 : 1 },
    to: { opacity: animationDirection === 'mount' ? 1 : 0 },
  });

  return (
    <Container style={{ ...contentAnime, ...style }}>{children}</Container>
  );
}

const Container = styled(animated.section)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${palette.lightTransparent};
`;

export default Content;
