import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AnimatedRoute from '../../components/AnimatedRoute';
import { palette } from '../../theme/palette';
import { pageViewEvent, SSPSubmitted } from '../../actions/segment';
import {
  localStorageGet,
  localStorageSave,
} from '../../utils/localStorageHelper';
import OshiMultiCheckbox from '../../components/OshiMultiCheckbox';
import useWindowSize from '../../hooks/useWindowSize';
import withTracker from '../../wrappers/withTracker';
import { createTrackEvent } from '../../actions/segment';
import { HEALTH_GOALS_OPTIONS } from '../../constants/formOptions';
import NewCustomTitle from '../../components/NewCustomTitle';
import PageFooter from '../../components/PageFooter'

function HealthGoals({ SSPSubmitted }) {
  const localStorageKey = 'healthGoalsSelect';
  const goals = localStorageGet(localStorageKey) || HEALTH_GOALS_OPTIONS;
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [items, setItems] = useState(goals);
  const [disabled, setDisabled] = useState(true);
  const { screenSize } = useWindowSize();

  const getTitle = (title, subHeader) => {
    return (
      <NewCustomTitle
        title={getTitleComponent(title)}
        subHeader={getSubHeaderComponent(subHeader)}
        style={{ padding: '0' }}
        headlineStyle={{ padding: screenSize.isMobile ? '24px 0' : '0' }}
      />
    );
  };

    const getTitleComponent = (title) => {
      return <Title>{title}</Title>;
    };
  
    const getSubHeaderComponent = (subHeader) => {
      return <SubHeader>{subHeader}</SubHeader>;
    };

  useEffect(() => {
    const validateSelection = () => {
      const someSelected = goals.some((item) => item.isChecked);
      setDisabled(!someSelected);
    };
    validateSelection();
  }, [goals]);

  const handleOnSubmit = () => {
    SSPSubmitted({
      selectedgoals: goals
        .filter((item) => item.isChecked)
        .map((item) => item.title),
    });
    return setAnimationDirection('unmount');
  };

  const onClickItem = (key) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    setItems(updatedItems);
    localStorageSave(localStorageKey, updatedItems);
  };

  const title = `What are you looking to achieve with Oshi Health?`;
  const subHeader = 'Select all of your health goals';

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute='/we-can-help'
        title={getTitle(title, subHeader)}
        titleStyle={{ paddingBottom: 0 }}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={true}
        showProviderBanner={true}
      >
        <Container>
          <Content>
            <OshiMultiCheckbox
              data={goals}
              onChange={onClickItem}
            ></OshiMultiCheckbox>
          </Content>
          <PageFooter
            primaryText='Continue'
            onClickContinue={handleOnSubmit}
            isContinueDisabled={disabled}
          />
        </Container>
      </AnimatedRoute>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
`;


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-bottom: 200px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 0px;
  }
`;

const Content = styled.div`
  font-family: 'Commissioner', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${palette.darkPrimaryText};
  flex: 1;
  font-size: 16px;
  padding: 0;

  @media only screen and (max-height: 300px) {
    padding: 0px 0px 120px 0;
  }

  @media only screen and (min-width: 1136px) {
    font-size: 24px;
  }
`;

const Title = styled.h1`
  font-family: Source Serif Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.2px;
  color: ${palette.white};
  /* h1.desktop */
  @media only screen and (min-width: 769px) {
    font-weight: 700;
    font-size: 42px;
    line-height: 130%; 
    color: ${palette.navy500};
  }
`;

const SubHeader = styled.p`
  color: ${palette.white};
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 12px;

  @media only screen and (min-width: 769px) {
    color: ${palette.coolGray};
  }
`;


export default connect(null, {
  createTrackEvent,
  pageViewEvent,
  SSPSubmitted,
})(withTracker(HealthGoals, 'Signup - Health Goals - Page View'));
