import { useEffect } from 'react';
import { getNextStep, getOnboardingFlowByEntryUrl } from '../utils/onboardingFlow';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { useDispatch } from 'react-redux';
import { uiTypes } from '../constants';

const useOnboardingLogic = () => {
  const location = useLocation();
  const currentUrl = location.pathname.split('/')[1];
  const onboardingFlow = getOnboardingFlowByEntryUrl(`/${currentUrl}`);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!onboardingFlow) {
      localStorageSave('onboardingFlowKey', onboardingFlow.key);
      dispatch({
        type: uiTypes.SETUP_ONBOARDING_FLOW,
        payload: onboardingFlow,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const onboardingFlowkey = localStorageGet('onboardingFlowKey')
  const nextRoute = getNextStep(onboardingFlowkey, `/${currentUrl}`)

  return {
    onboardingFlow,
    nextRoute
  };
};

export default useOnboardingLogic;
