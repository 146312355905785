export const ROUTES = {
  INTRO: '/intro',
  INTRO_2: '/intro-2',
  NAME: '/name',
  NAME_STEP: '/name-step',
  SELECT_SYMPTOMS:'/select-symptoms',
  SUCCESS_RATE: '/success-rate',
  GI_CONDITIONS: '/gi-conditions',
  WE_CAN_HELP: '/we-can-help',
  PIM_ID: '/pimid',
  EMAIL: '/email',
  GENDER: '/gender',
  BIRTHDATE: '/birthdate',
  STREET_ADDRESS: '/street-address',
  ADDRESS: '/address',
  PASSWORD: '/password',
  PRIVACY_POLICY: '/privacy-policy',
  HEALTH_PLAN: '/health-plan',
  FEDERAL_PLAN: '/has-federal-plan',
  CURRENT_PARTNERS: '/current-partners',
  NEW_PARTNER: '/new-partner',
  SELF_PAY: '/self-pay',
  OFF_BOARDING: '/offboarding',
  GET_SYMPTOM_RELIEF: '/get-symptom-relief',
  FIRST_APPT_BOOK: '/first-appointment-book',
  FIRST_APPT_CONFIRM: '/first-appointment-confirm',
  FIRST_APPT_OVERVIEW: '/first-appointment-overview',
  SIGN_IN: '/signin',
  RESET_PASS_EMAIL: '/reset-password-email',
  RESET_PASS_CODE: '/reset-password-code',
  RESET_PASS: '/reset-password',
  INSURANCE_INFO: '/insurance-information',
  INSURANCE_CARD_SCAN: '/insurance-card-take-a-picture',
  INSURANCE_CARD_UPLOAD: '/insurance-card-image-upload',
  POLICY_HOLDER: '/policy-holder',
  NON_POLICY_HOLDER: '/policy-holder-relationship',
  NETWORK_ERROR: '/network-error',
  HEALTH_GOALS: '/health-goals',
};

export const regularSignupRouteList = [
  {
    step: 0,
    routes: [ROUTES.NAME],
  },
  {
    step: 1,
    routes: [ROUTES.EMAIL],
  },
  {
    step: 2,
    routes: [ROUTES.GENDER],
  },
  {
    step: 3,
    routes: [ROUTES.BIRTHDATE],
  },
  {
    step: 4,
    routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS],
  },
  {
    step: 5,
    routes: [ROUTES.PASSWORD],
  },
  {
    step: 6,
    routes: [ROUTES.PRIVACY_POLICY],
  },
];

export const genericSignupRouteList = [
  ...regularSignupRouteList.slice(0, 5),
  {
    step: 5,
    routes: [ROUTES.HEALTH_PLAN, ROUTES.CURRENT_PARTNERS, ROUTES.NEW_PARTNER],
  },
  {
    step: 6,
    routes: [ROUTES.FEDERAL_PLAN],
  },
  {
    step: 7,
    routes: [ROUTES.PASSWORD],
  },
  {
    step: 8,
    routes: [ROUTES.PRIVACY_POLICY],
  },
];

export const getSignUpFlowList = (isGeneric) => {
  const list = isGeneric ? genericSignupRouteList : regularSignupRouteList;
  return list.map((item) => item.routes.join('|'));
}

export const getPagesListBeforeLastRoute = () => {
  return  [
    ROUTES.SIGN_IN,
    ROUTES.FIRST_APPT_BOOK,
    ROUTES.NON_POLICY_HOLDER,
  ];
}

export const signUpRoutes = {
  '/intro': true,
  '/name': true,
  '/name-step': true,
  '/name/optum': true,
  '/email': true,
  '/password': true,
  '/privacy-policy': true,
  '/gender': true,
  '/birthdate': true,
  '/street-address': true,
  '/address': true,
};
