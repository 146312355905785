import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import BackgroundAnimation from '../../assets/92-stat-background-animation.json';
import OshiNextButton from '../../components/OshiNextButton';
import MainAnimation from '../../assets/92-percent-simplified-animation-prototype 2.json';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageViewEvent, successRateSubmit } from '../../actions/segment';
import withTracker from '../../wrappers/withTracker';
import { palette } from '../../theme/palette';
import useWindowSize from '../../hooks/useWindowSize';
import { ArrowRightRounded } from '../../assets/svgs'

function SuccessRate({successRateSubmit}) {
  const lottieRef = React.useRef(null);
  const backgroundLottieRef = React.useRef(null);
  const [showButton, setShowButton] = useState(false);
  const { screenSize } = useWindowSize();
  const history = useHistory();


  const getMainAnimation = () => (
    <Lottie
      options={{
        animationData: MainAnimation,
        loop: false,
        autoplay: false,
      }}
      isPaused={true}
      ref={lottieRef}
      ariaLabel='Your gut health is our priority'
      ariaRole='none'
      eventListeners={[
        {
          eventName: 'enterFrame',
          callback: (x) => {
            if (x.currentTime >= 70 && showButton === false) {
              setShowButton(true);
            }
          },
        },
      ]}
    />
  );

  const getBackgroundLottie = () => (
    <Lottie
      options={{
        animationData: BackgroundAnimation,
        loop: false,
        autoplay: true,
      }}
      ref={backgroundLottieRef}
      ariaRole='none'
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            const animation = lottieRef.current.anim;
             animation.play();
          },
        },
      ]}
    />
  );

  const handleNext = () => {
    successRateSubmit();
    history.push('gi-conditions');
  };
  const getButton = () => {
    return (
      <OshiNextButton
        buttonContainerStyles={{
          alignItems: 'center',
          margin: screenSize.isMobile ? '24px' : '0',
        }}
        buttonTitle={
          <span>
            Continue
            <RightIconArrow>
              <ArrowRightRounded color={palette.melon800} />
            </RightIconArrow>
          </span>
        }
        onClick={handleNext}
      ></OshiNextButton>
    );
  };
  return (
    <PageContainer>
      <BackgroundContainer>{getBackgroundLottie()}</BackgroundContainer> 
      <MainAnimationContainer>
        {getMainAnimation()}

        {!screenSize.isMobile && (
          <NextBtnContainer
            style={{
              opacity: showButton ? 1 : 0,
              transform: showButton ? 'translateY(0)' : 'translateY(100px)',
            }}
          >
            {getButton()}
          </NextBtnContainer>
        )}
      </MainAnimationContainer>
      {screenSize.isMobile && (
        <MobileBtnContainer
          style={{
            opacity: showButton ? 1 : 0,
            transform: showButton ? 'translateY(0)' : 'translateY(100px)',
          }}
        >
          {getButton()}
        </MobileBtnContainer>
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
`;

const MainAnimationContainer = styled.div`
  width: 100%;
  height: min-content;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 0;
  @media only screen and (max-width: 769px) {
    margin-bottom: 80px;
    height: 100%;
  }
`;

const NextBtnContainer = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
`;

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

const MobileBtnContainer = styled.div`
  position: fixed;
  max-width: 100%;
  width: 100%;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  max-width: 517px;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: -50%;
  left: -30%;
  width: 200%;
  overflow: hidden;
  height: 200%;
  background-color: ${palette.navy500};
`;

export default connect(null, { pageViewEvent, successRateSubmit })(
  withTracker(SuccessRate, 'Signup - SuccessRate - Page View')
);