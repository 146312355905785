import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AnimatedRoute from '../../components/AnimatedRoute';
import { palette } from '../../theme/palette';
import {
  giConditionsSkip,
  giConditionsSubmit,
  pageViewEvent,
} from '../../actions/segment';

import {
  localStorageGet,
  localStorageSave,
} from '../../utils/localStorageHelper';
import OshiMultiCheckbox from '../../components/OshiMultiCheckbox';
import useWindowSize from '../../hooks/useWindowSize';
import withTracker from '../../wrappers/withTracker';
import { createTrackEvent } from '../../actions/segment';
import NewCustomTitle from '../../components/NewCustomTitle';
import { GI_CONDITIONS } from '../../constants/gi-conditons';
import PageFooter from '../../components/PageFooter';

function GiConditions({ giConditionsSubmit }) {
  const nextRoute = '/testimonials';
  const history = useHistory();
  const symptoms = localStorageGet('giConditions') || GI_CONDITIONS;
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [items, setItems] = useState(symptoms);
  const [disabled, setDisabled] = useState(true);
  const { screenSize } = useWindowSize();

  const getTitle = (title, subHeader) => {
    return (
      <NewCustomTitle
        title={getTitleComponent(title)}
        subHeader={getSubHeaderComponent(subHeader)}
        style={{ padding: '0' }}
        headlineStyle={{ padding: screenSize.isMobile ? '24px 0' : '0' }}
      />
    );
  };

  const getTitleComponent = (title) => {
    return <Title>{title}</Title>;
  };

  const getSubHeaderComponent = (subHeader) => {
    return <SubHeader>{subHeader}</SubHeader>;
  };

  useEffect(() => {
    const validateSelection = () => {
      const someSelected = symptoms.some((item) => item.isChecked);
      setDisabled(!someSelected);
    };
    validateSelection();
  }, [symptoms]);

  const handleOnSubmit = () => {
    giConditionsSubmit({
      giConditions: symptoms
        .filter((item) => item.isChecked)
        .map((item) => item.title),
    });
    history.push(nextRoute);
    return setAnimationDirection('unmount');
  };

  const onClickItem = (key) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    setItems(updatedItems);
    localStorageSave('giConditions', updatedItems);
  };

  const handleSkip = () => {
    giConditionsSkip();
    history.push(nextRoute);
    return setAnimationDirection('unmount');
  };

  const title = `Have you been diagnosed with any GI conditions?`;
  const subHeader = `Select all conditions you’ve been diagnosed with below.`;

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute='/success-rate'
        title={getTitle(title, subHeader)}
        titleStyle={{paddingBottom: 0}}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={true}
        showProviderBanner={true}
      >
        <Container>
          <Content>
            <OshiMultiCheckbox
              data={symptoms}
              onChange={onClickItem}
            ></OshiMultiCheckbox>
          </Content>
          <PageFooter
            primaryText='Continue'
            secondaryText='Skip'
            onClickContinue={handleOnSubmit}
            onClickSkip={handleSkip}
            isContinueDisabled={disabled}
          />
        </Container>
      </AnimatedRoute>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-bottom: 150px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 0px;
  }
`;

const Content = styled.div`
  font-family: 'Commissioner', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${palette.darkPrimaryText};
  flex: 1;
  font-size: 16px;
  padding: 0;

  @media only screen and (max-height: 300px) {
    padding: 0px 0px 120px 0;
  }

  @media only screen and (min-width: 1136px) {
    font-size: 24px;
  }
`;

const Title = styled.h1`
  font-family: Source Serif Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.2px;
  color: ${palette.white};
  /* h1.desktop */
  @media only screen and (min-width: 769px) {
    font-weight: 700;
    font-size: 42px;
    line-height: 130%; /* 72.8px */
    color: ${palette.navy500};
  }
`;

const SubHeader = styled.p`
  color: ${palette.white};
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 12px;

  @media only screen and (min-width: 769px) {
    color: ${palette.coolGray};
  }
`;

export default connect(null, {
  createTrackEvent,
  pageViewEvent,
  giConditionsSubmit,
})(withTracker(GiConditions, 'Signup - Gi Conditions - Page View'));
