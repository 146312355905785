import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';
import { palette } from '../../theme/palette';
import MainAnimation from '../../assets/testimonial-text-animation - simplified approach.json';
import TopRight from '../../assets/testimonial background - teal blob top right.json';
import BottomLeft from '../../assets/testimonial background - melon blob bottom left.json';
import OshiNextButton from '../../components/OshiNextButton';
import useWindowSize from '../../hooks/useWindowSize';
import withTracker from '../../wrappers/withTracker';
import { ArrowRightRounded } from '../../assets/svgs';

function Testimonials() {
  const history = useHistory();
  const [showButton, setShowButton] = useState(false);
  const { screenSize } = useWindowSize();

  const lottieRef = useRef(null);
  const bgLottieRef = useRef(null);

  const handleNext = () => {
    history.push('health-goals');
  };

  useEffect(() => {
    const animation = lottieRef.current.anim;
    if (animation && showButton === false) {
      animation.setSpeed(0);
    }
  }, [showButton, lottieRef]);

  const getTopRight = () => (
    <Lottie
      options={{
        animationData: TopRight,
        loop: false,
        autoplay: true,
      }}
      ariaRole='none'
    />
  );

  const getBottomLeft = () => (
    <Lottie
      options={{
        animationData: BottomLeft,
        loop: false,
        autoplay: true,
      }}
      isPaused={true}
      ref={bgLottieRef}
      ariaRole='none'
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            const animation = lottieRef.current.anim;
            console.log(animation);
            if (animation.playSpeed === 0) {
              animation.play();
              animation.setSpeed(1);
            }
          },
        },
      ]}
    />
  );

  const getMainAnimation = () => (
    <Lottie
      options={{
        animationData: MainAnimation,
        loop: false,
        autoplay: false,
      }}
      ref={lottieRef}
      ariaLabel='Your gut health is our priority'
      ariaRole='none'
      eventListeners={[
        {
          eventName: 'enterFrame',
          callback: (x) => {
            if (x.currentTime >= 140 && showButton === false) {
              setShowButton(true);
            }
          },
        },
      ]}
    />
  );

  const getButton = () => {
    return (
      <OshiNextButton
        styles={{
          opacity: showButton ? 1 : 0,
          transform: showButton ? 'translateY(0)' : 'translateY(100px)',
          transition: 'all 0.3s ease-in-out',
        }}
        buttonContainerStyles={{
          alignItems: 'center',
          margin: screenSize.isMobile ? '24px' : '0',
        }}
        buttonTitle={
          <span>
            Continue
            <RightIconArrow>
              <ArrowRightRounded color={palette.melon800} />
            </RightIconArrow>
          </span>
        }
        onClick={handleNext}
      ></OshiNextButton>
    );
  };
  return (
    <PageContainer>
      <BackgroundContainer>
        <TopRightContainer>{getTopRight()}</TopRightContainer>
        <BottomLeftContainer>{getBottomLeft()}</BottomLeftContainer>
      </BackgroundContainer>

      <MainAnimationContainer>
        {getMainAnimation()}
        {!screenSize.isMobile && (
          <NextBtnContainer>{getButton()}</NextBtnContainer>
        )}
      </MainAnimationContainer>
      {screenSize.isMobile && (
        <MobileBtnContainer>{getButton()}</MobileBtnContainer>
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${palette.navy500};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MainAnimationContainer = styled.div`
  width: 100%;
  height: min-content;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 0;
  @media only screen and (max-width: 769px) {
    margin-bottom: 80px;
    height: 100%;
  }
`;

const BottomLeftContainer = styled.div`
  position: fixed;
  height: 100vh;
  transform: scale(4, 4) translate(-10%, 15%);
  @media only screen and (min-width: 769px) {
    transform: scale(3, 3) translate(-15%, 25%);
  }
`;

const TopRightContainer = styled.div`
  position: fixed;
  height: 100vh;
  transform: scale(3, 3) translate(40%, -30%);
  right: 0;
  top: 0;
  @media only screen and (min-width: 769px) {
    transform: scale(2.5, 2.5) translate(30%, -50%);
  }
`;

const NextBtnContainer = styled.div`
  position: relative;
  padding-bottom: 24px;
  transition: all 0.3s ease-in-out;
`;

const MobileBtnContainer = styled.div`
  position: fixed;
  max-width: 100%;
  width: 100%;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  max-width: 517px;
`;

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

export default connect(
  null,
  null
)(withTracker(Testimonials, 'Signup - Testimonials - Page View'));
