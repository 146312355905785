import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { palette } from '../theme/palette';
import { useSelector } from 'react-redux';

import OshiBanner from '../components/OshiBanner';
import useWindowSize from '../hooks/useWindowSize';
import { getHeadlineListUIComp, getTitleText } from '../utils/stringHelpers';
import { MobileHeaderBlob } from '../assets/svgs';
import { isAComponent } from '../utils/domHelpers';
import { ROUTES } from '../constants/routes';

function AnimatedTitle({
  animationDirection,
  animationRef,
  resetAnimaions,
  cameAfterBrowserBackButtonClick,
  cameAfterOshiHeaderBackButtonClick,
  title,
  titleStyle,
  preHeader = null,
  routeName,
  showProgressBar,
  titleContainerStyle,
}) {
  const { screenSize } = useWindowSize();
  const hasTitleComponent =
    isAComponent(title) || (typeof title === 'string' && title.length > 0);
  const showBanner = useSelector((store) => store.uiReducer?.showBanner);
  const isLastPage = routeName === ROUTES.FIRST_APPT_OVERVIEW.slice(1);
  const isNoHeaderPage =
    routeName === ROUTES.NEW_PARTNER.slice(1) ||
    routeName === ROUTES.OFF_BOARDING.slice(1);

  const getDeviceTypeByScreenWidthSize = React.useCallback(() => {
    if (screenSize.width > 768) {
      return 'tablet';
    }
    return 'mobile';
  }, [screenSize]);

  function getAnime() {
    let anime;

    if (animationDirection === 'mount') {
      anime = {
        from: {
          opacity: 0,
          transform: cameAfterBrowserBackButtonClick
            ? 'translate3d(-100%,0,0)'
            : 'translate3d(100%,0,0)',
        },
        to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      };
    } else if (animationDirection === 'unmount') {
      anime = {
        from: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        to: {
          opacity: 0,
          transform: cameAfterOshiHeaderBackButtonClick
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
        },
      };
    }

    return anime;
  }

  const anime = getAnime();

  const titleAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    ...anime,
  });

  function getBanner() {
    const defaultStyles = {
      marginTop: 0,
      marginBottom: 15,
      background: palette.melon300,
      border: 'none',
    };
    if (isAComponent(title)) {
      defaultStyles.marginBottom = 0;
      defaultStyles.marginTop = 0;
      defaultStyles.zIndex = 11;
    }
    return (
      <OshiBanner showProgressBar={showProgressBar} styles={defaultStyles} />
    );
  }

  const calculateTitleTopMargin = React.useCallback(() => {
    const hasBanner = Boolean(showBanner);
    const hasProgressBar = showProgressBar;
    const totalHeaderHeight = hasProgressBar ? 80 : 58; // 58px from header + 6px progress bar + 16px bottom margin = 80px
    let topMargin = totalHeaderHeight;
    // Since banner is on top of title component then it will handle how much margin it needs
    // Additionally if screen width is tablet based or beyond then we don't need to add margin
    if (
      hasBanner ||
      getDeviceTypeByScreenWidthSize() === 'tablet' ||
      isNoHeaderPage
    ) {
      topMargin = 0;
    }

    return `${topMargin}px`;
  }, [
    showProgressBar,
    showBanner,
    getDeviceTypeByScreenWidthSize,
    isNoHeaderPage,
  ]);

  const getTitle = React.useCallback(() => {
    if (isAComponent(title)) {
      return title;
    }
    return getHeadlineListUIComp(
      getTitleText(title, screenSize.isMobile),
      MobileTitle
    );
  }, [title, screenSize.isMobile]);

  return (
    <>
      {showBanner ? (
        <BannerContainer showprogressbar={showProgressBar}>
          {getBanner()}
        </BannerContainer>
      ) : null}

      {hasTitleComponent ? (
        <TitleComponentContainer
          verticaltopspace={calculateTitleTopMargin()}
          islastpage={isLastPage}
          isnoheaderpage={isNoHeaderPage}
          style={titleContainerStyle}
        >
          {screenSize.isMobile && (
            <BlobContainer>
              <MobileHeaderBlob height={'100%'} />
            </BlobContainer>
          )}

          <TitleContainer
            isuicomponent={isAComponent(title).toString()}
            style={{ ...titleAnime, ...titleStyle }}
          >
            {preHeader ? <PreHeaderText>{preHeader}</PreHeaderText> : null}
            {getTitle()}
            
          </TitleContainer>
        </TitleComponentContainer>
      ) : null}
    </>
  );
}

const PreHeaderText = styled.p`
  color: ${palette.coolGray};
  font-family: Usual;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 0;

  @media only screen and (max-width: 768px) {
    color: ${palette.melon};
  }
`;

const MobileTitle = styled.h1`
  color: ${palette.navy500};
  font-family: Source Serif Pro;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 72.8px */

  @media only screen and (max-width: 768px) {
    color: ${palette.white};
    font-family: 'Source Serif Pro', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  }
`;

const TitleContainer = styled(animated.section)`
  padding: ${(props) => (props.isuicomponent === 'true' ? '0 20px' : '20px')};
  display: flex;
  flex-direction: column;
  background-color: transparent;
  wrap-content: wrap;
  max-width: 542px;
  @media only screen and (min-width: 769px) {
    padding: 20px 0;
    background-color: ${palette.white};
  }
`;

const BannerContainer = styled.div`
  margin-top: ${(props) => (props.showprogressbar ? '80px' : '74px')};
  width: 100vw;
  @media only screen and (min-width: 769px) {
    margin-top: 0px;
  }
  @media only screen and (min-width: 1136px) {
    width: 60vw;
  }
`;

const TitleComponentContainer = styled.div`
  margin-top: ${(props) => props.verticaltopspace};
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: ${({ isnoheaderpage }) => (isnoheaderpage ? 58 : 0)}px;
  background-color: ${palette.navy500};
  @media only screen and (min-width: 769px) {
    background-color: ${palette.white};
    margin-top: 0px;
    width: 100%;
  }
`;

const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export default React.memo(AnimatedTitle);
