export const GI_CONDITIONS = [
    {
      title: 'Acid Reflux/ GERD',
      key: 'acid_reflux_gerd',
      isChecked: false,
      error: false,
    },
    {
      title: `Crohn's Disease`,
      key: 'chrons_disease',
      isChecked: false,
      error: false,
    },
    {
      title: 'Irritable Bowel Syndrome (IBS)',
      key: 'ibs',
      isChecked: false,
      error: false,
    },
    {
      title: 'Small Intestinal Bacterial Overgrowth (SIBO)',
      key: 'sibo',
      isChecked: false,
      error: false,
    },
    {
      title: 'Ulcerative colitis',
      key: 'ulcerative_colitis',
      isChecked: false,
      error: false,
    },
    {
      title: 'Other GI condition',
      key: 'other_gi_condition',
      isChecked: false,
      error: false,
    },
    {
      title: 'I don’t know',
      key: 'unknown',
      isChecked: false,
      error: false,
    },
  ];