import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Welcome from '../containers/Welcome';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import Name from '../containers/Name';
import NameStep from '../containers/onboarding/NameStep';
import Email from '../containers/Email';
import Password from '../containers/Password';
import AccountCreated from '../containers/AccountCreated';
import Sex from '../containers/Sex';
import Birthdate from '../containers/Birthdate';
import Address from '../containers/Address';
import StreetAddress from '../containers/StreetAddress';
import Skip from '../containers/Skip';
import OffBoarding from '../containers/OffBoarding';
import AccountCreationError from '../containers/AccountCreationError';
import CancelationPolicy from '../containers/CancelationPolicy';
import ResetPassword from '../containers/ResetPassword';
import ResetPasswordEmail from '../containers/ResetPasswordEmail';
import ResetPasswordCode from '../containers/ResetPasswordCode';
import InsuranceInformation from '../containers/InsuranceInformation';
import CardScan from '../containers/InsuranceCardScan';
import CardUpload from '../containers/InsuranceCardUpload';

import AppointmentBook from '../containers/AppointmentBook';
import AppointmentOverview from '../containers/AppointmentOverview';
import AppointmentConfirm from '../containers/AppointmentConfirm';
import MembershipTransition from '../containers/MembershipTransition';
import MembershipCashPay from '../containers/MembershipCashPay';
import NetworkError from '../containers/NetworkError';
import DeeplinkHandle from '../containers/DeeplinkHandle';
import FourOhFour from '../containers/FourOhFour';
import ProtectedRoute from './ProtectedRoute';
import PimIdRoute from './PimIdRoute';
import useBrowserTabActiveness from '../hooks/useBrowserTabActiveness';
import { userTabActivenessEvent } from '../actions/segment';
import { setIsFetching } from '../actions';
import SignIn from '../containers/SignIn';
import HealthPlan from '../containers/HealthPlan';
import CurrentPartners from '../containers/CurrentPartners';
import useSignUpProgress from '../hooks/useSignUpProgress';
import NewPartner from '../containers/NewPartner';
import SelfPay from '../containers/SelfPay';
import FederalHealthPlan from '../containers/FederalHealthPlan';
import GuardedRoute from './GuardedRoute';
import useUserRouteCheck from '../hooks/useUserRouteCheck';
import { UserContext } from '../utils/context';
import PolicyHolder from '../containers/PolicyHolder';
import NonPolicyHolder from '../containers/NonPolicyHolder';
import Intro from '../containers/onboarding/Intro';
import Testimonials from '../containers/onboarding/Testimonials';
import SymptomsSelection from '../containers/onboarding/SymptomsSelection';
import HealthGoals from '../containers/onboarding/HealthGoals'
import GIConditions from '../containers/onboarding/GIConditions';
import SuccessRate from '../containers/onboarding/SuccessRate';
import WeCanHelp from '../containers/onboarding/WeCanHelp';

const Routes = ({
  userTabActivenessEvent: userTabActiveness,
  setIsFetching: setFetching,
}) => {
  const { loggedIn } = React.useContext(UserContext);
  useBrowserTabActiveness(userTabActiveness);
  useSignUpProgress();
  useUserRouteCheck({ setIsFetching: setFetching });

  return (
    <Switch>
      <Route path='/cancelation-policy'>
        <CancelationPolicy />
      </Route>
      <Route path='/reset-password-email'>
        <ResetPasswordEmail />
      </Route>
      <Route path='/reset-password-code'>
        <ResetPasswordCode />
      </Route>
      <Route path='/reset-password'>
        <ResetPassword />
      </Route>
      <Route path='/signin'>
        <SignIn />
      </Route>
      <Route exact path='/name/:partnerName'>
        <Name />
      </Route>
      <Route exact path='/name'>
        <Name />
      </Route>
      <Route exact path='/name-step'>
        <NameStep />
      </Route>
      <Route exact path='/select-symptoms'>
        <SymptomsSelection />
      </Route>
      <Route exact path='/health-goals'>
        <HealthGoals />
      </Route>
      <Route exact path='/intro'>
        <Intro />
      </Route>
      <Route exact path='/intro-2'>
        <Intro />
      </Route>
      <Route exact path='/testimonials'>
        <Testimonials />
      </Route>
      <Route exact path='/success-rate'>
        <SuccessRate />
      </Route>
      <Route exact path='/gi-conditions'>
        <GIConditions />
      </Route>
      <Route exact path='/we-can-help'>
        <WeCanHelp />
      </Route>
      <ProtectedRoute path='/pimid' childCmp={PimIdRoute} />
      <Route path='/email'>
        <Email />
      </Route>
      <Route path='/password'>
        <Password />
      </Route>
      <Route path='/account-created'>
        <AccountCreated />
      </Route>
      <Route path='/gender'>
        <Sex />
      </Route>
      <Route path='/birthdate'>
        <Birthdate />
      </Route>
      <Route path='/street-address'>
        <StreetAddress />
      </Route>
      <Route path='/address'>
        <Address />
      </Route>
      <Route path='/health-plan'>
        <HealthPlan />
      </Route>
      <Route path='/current-partners'>
        <CurrentPartners />
      </Route>
      <Route path='/new-partner'>
        <NewPartner />
      </Route>
      <Route path='/self-pay'>
        <SelfPay />
      </Route>
      <Route path='/has-federal-plan'>
        <FederalHealthPlan />
      </Route>
      <Route path='/privacy-policy'>
        <PrivacyPolicy />
      </Route>
      <GuardedRoute
        path='/first-appointment-book'
        component={AppointmentBook}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/first-appointment-overview'
        component={AppointmentOverview}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/first-appointment-confirm'
        component={AppointmentConfirm}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/insurance-information'
        component={InsuranceInformation}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/insurance-card-take-a-picture'
        component={CardScan}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/insurance-card-image-upload'
        component={CardUpload}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/policy-holder'
        component={PolicyHolder}
        auth={loggedIn}
      />
      <GuardedRoute
        path='/policy-holder-relationship'
        component={NonPolicyHolder}
        auth={loggedIn}
      />
      <Route path='/membership-transition'>
        <MembershipTransition />
      </Route>
      <Route path='/membership-cash-pay'>
        <MembershipCashPay />
      </Route>
      <Route path='/skip'>
        <Skip />
      </Route>
      <Route path='/offboarding'>
        <OffBoarding />
      </Route>
      <Route path='/network-error'>
        <NetworkError />
      </Route>
      <Route path='/account-creation-error'>
        <AccountCreationError />
      </Route>
      <Route path='/go-to-app'>
        <DeeplinkHandle />
      </Route>
      <Route exact path='/'>
        <Welcome />
      </Route>
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default connect(null, { setIsFetching, userTabActivenessEvent })(Routes);
