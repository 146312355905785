import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import { palette } from '../theme/palette';
import Anime from '../assets/rs-banner-anime.json';
import { HeroDocBlob, ZeroCostBlob } from '../assets/svgs';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';

function BackgroundBanner({ maxWidth, isNameRoute, isRightLayout }) {
  const splitUrl = window.location.pathname.split('/');
  const partnerName = splitUrl[2];
  const hasZeroCostPartner = ZERO_COST_PARTNERS.includes(partnerName);
  const { hideRightSidedBanner } = useSelector((store) => ({
    hideRightSidedBanner: store.uiReducer?.hideRightSidedBanner,
  }));
  const lottieRef = React.useRef(null);

  React.useEffect(() => {
    if (lottieRef.current) lottieRef.current.el.setAttribute('tabindex', -1);
  }, []);

  if (hideRightSidedBanner) return null;

  const getLottie = () => (
    <Lottie
      options={{
        animationData: Anime,
        loop: true,
        autoplay: true,
      }}
      loop
      ref={lottieRef}
      ariaRole='none'
    />
  );

  return (
    <RightContainer
      tabIndex={-1}
      style={{ maxWidth }}
      isRightLayout={isRightLayout}
    >
      {isRightLayout ? (
        <LeftLottieContainer>{getLottie()}</LeftLottieContainer>
      ) : (
        <LottieContainer>{getLottie()}</LottieContainer>
      )}

      <BlobContainer>
        {hasZeroCostPartner && isNameRoute && (
          <DocBlob role='presentation' aria-label='' />
        )}
        {hasZeroCostPartner ? (
          <CostBlob
            role='img'
            aria-label='Available at zero dollar cost to you'
          />
        ) : null}
      </BlobContainer>
    </RightContainer>
  );
}

const RightContainer = styled.aside`
  display: flex;
  width: 40%;
  z-index: 10;
  max-width: 40%;
  background: ${palette.navy500};
  align-items: center;
  justify-content: center;

  ${({ isRightLayout }) =>
    isRightLayout
      ? `
        @media (max-width: 768px) {
          display: none;
        }
      `
      : `
        @media (max-width: 1135px) {
          display: none;
        }
      `}
`;

const LottieContainer = styled.div`
  position: absolute;
  z-index: 0;
`;

const LeftLottieContainer = styled.div``;

const BlobContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const DocBlob = styled(HeroDocBlob)`
  position: absolute;
  z-index: 1;
`;
const CostBlob = styled(ZeroCostBlob)`
  margin-top: -172px;
  margin-left: -348px;
  z-index: 2;
`;

const mapStateToProps = ({ uiReducer }) => ({
  isNameRoute: uiReducer.isNameRoute,
});

export default connect(mapStateToProps)(BackgroundBanner);
