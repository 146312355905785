import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import { palette } from '../theme/palette';

import nonZeroCostAnimation from '../assets/sign-up-animation-non-zero.json';
import zeroCostAnimation from '../assets/sign-up-animation.json';
import loadingDots from '../assets/loading-dots.json';
import BackgroundBanner from '../atoms/RightSidedBanner';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';
import { localStorageGet } from '../utils/localStorageHelper';

function BookAnAppointment({ onAnimationComplete }) {
  const { partner } = localStorageGet('partner');
  const { selectedPartner } = localStorageGet('selectedPartner');
  const partnerName = selectedPartner || partner;

  const [isLoading, setIsLoading] = useState(false);
  const [animation, setAnimation] = useState();

  useLayoutEffect(() => {
    partnerName &&
      setAnimation(
        ZERO_COST_PARTNERS.includes(partnerName)
          ? zeroCostAnimation
          : nonZeroCostAnimation
      );
  }, [partnerName]);

  const Loader = () => (
    <LoaderContainer>
      <LoaderText>almost there</LoaderText>
      <LoadingAnimation>
        <Lottie
          options={{
            animationData: loadingDots,
            loop: true,
          }}
          ariaLabel='Almost there, please wait'
          ariaRole='img'
        />
      </LoadingAnimation>
    </LoaderContainer>
  );

  function onComplete() {
    setTimeout(() => setIsLoading(true), 1000);
    onAnimationComplete && onAnimationComplete();
  }

  return (
    <MainContainer>
      <Container>
        { isLoading && <Loader /> }
        <Lottie
          options={{
            animationData: animation,
            loop: false,
          }}
          ariaLabel='Almost 92% of members find symptom control in 10 weeks or less.'
          ariaRole='img'
          eventListeners={[{ eventName: 'complete', callback: onComplete }]}
        />
      </Container>

      <BackgroundBanner />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const Container = styled.main`
  width: 100vw;
  top: 58px;
  height: calc(100% - 58px);
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${palette.background};
  position: relative;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    top: 90px;
    height: calc(100% - 90px);
  }
`;

const LoaderText = styled.p`
  @media only screen and (min-width: 769px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  align-self: center;
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const LoadingAnimation = styled.div`
  @media only screen and (min-width: 769px) {
    height: 67px;
    width: 67px;
    margin-left: -20px;
  }
  align-self: flex-end;
  height: 50px;
  width: 50px;
  margin-left: -15px;
`;

const LoaderContainer = styled.div`
  @media only screen and (min-width: 769px) {
    left: calc(50% - 85px);
    top: -67px;
  }
  display: flex;
  flex: 1;
  justify-content: center;
  left: calc(50% - 63px);
  position: absolute;
  top: -50px;
`;

export default connect(null, null)(BookAnAppointment);
