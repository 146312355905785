import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Lottie from 'react-lottie';
import Anime from '../../assets/we-can-help.json';
import BlueBlobAnime from '../../assets/we-can-help-bg.json';
import MelonBlobAnime from '../../assets/melon_blob.json';
import OshiNextButton from '../../components/OshiNextButton';
import useWindowSize from '../../hooks/useWindowSize';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageViewEvent, weCanHelpSubmit } from '../../actions/segment';

import withTracker from '../../wrappers/withTracker';
import { palette } from '../../theme/palette';

function WeCanHelp({ weCanHelpSubmit }) {
  const lottieRef = React.useRef(null);
  const blueBlobRef = React.useRef(null);
  const mellonBlobRef = React.useRef(null);
  const [showContent, setShowContent] = useState(false);
  const { screenSize } = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const getLottie = () => (
    <Lottie
      width='100%'
      options={{
        animationData: Anime,
        loop: false,
        autoplay: true,
      }}
      ref={lottieRef}
      ariaRole='none'
    />
  );

  const getBlueBlob = () => (
    <Lottie
      options={{
        animationData: BlueBlobAnime,
        loop: false,
        autoplay: true,
      }}
      ref={blueBlobRef}
      ariaRole='none'
    />
  );

  const getMelonBlob = () => (
    <Lottie
      options={{
        animationData: MelonBlobAnime,
        loop: false,
        autoplay: true,
      }}
      ref={mellonBlobRef}
      ariaRole='none'
    />
  );

  const handleNext = () => {
    weCanHelpSubmit();
    history.push('email');
  };

  const history = useHistory();

  const PageContent = (
    <Content>
      <Headline> We can help with that!</Headline>
      <TextContent>
        <p>
          The average Oshi patient reports 3X better quality of life within just
          3 months. That’s why Oshi has a 98%+ patient satisfaction rating
        </p>
        <p>
          <strong>
            Now, let’s schedule your first online appointment with your GI care
            team.
          </strong>{' '}
          We’ll also collect your insurance information to see if you’re
          in-network.
        </p>
      </TextContent>

      <NextBtnContainer>
        <NextBtnWrapper>
          <OshiNextButton
            containerStyles={{
              boxSizing: 'border-box',
              bottom: 0,
              padding: screenSize.isMobile ? '0 24px' : '0',
              left: 0,
              flex: 0,
              backgroundColor: 'transparent',
              margin: 'auto',
              maxWidth: '648px',
            }}
            buttonContainerStyles={{ alignItems: 'center' }}
            styles={{ width: '100%' }}
            onClick={handleNext}
            buttonTitle={
              <span>
                Continue <RightIconArrow>→</RightIconArrow>
              </span>
            }
          />
        </NextBtnWrapper>
      </NextBtnContainer>
    </Content>
  );

  const AnalyzingLoader = (
    <AnalyzingWrapper>
      <AnalyzingContainer>
        <span>analyzing...</span>
        <AnalyzingBarContainer>
          <AnalyzingBar>
            <Progress />
          </AnalyzingBar>
        </AnalyzingBarContainer>
      </AnalyzingContainer>
    </AnalyzingWrapper>
  );

  return (
    <PageContainer>
      <BlueBackground />
      {!showContent && AnalyzingLoader}
      <BlueBlobContainer>{getBlueBlob()}</BlueBlobContainer>
      <MelonBlobContainer>{getMelonBlob()}</MelonBlobContainer>
      {showContent && (
        <>
          <LottieContainer>{getLottie()}</LottieContainer>
          <ContentWrapper>
            <LeftContainer> {PageContent}</LeftContainer>
            <RightContainer />
          </ContentWrapper>
        </>
      )}
    </PageContainer>
  );
}

const slideIn = keyframes`
  from {
    transform: translateX(-100%); 
    opacity: 0; 
  }
  to {
    transform: translateX(0); 
    opacity: 1;
  }
`;

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0; 
  }
  to {
    transform: translateY(0); 
    opacity: 1;
  }
`;

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  pointer-events: auto;
  z-index: 1;
`;

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: ${palette.navy500};
  z-index: 0;
`;

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 0%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  position: unset;

  @media only screen and (min-width: 769px) {
    position: relative;
    width: 40%;
  }
`;

const Content = styled.div`
  margin: auto;
  padding: 0 24px;
`;

const Headline = styled.h1`
  color: ${palette.white};
  font-family: 'Source Serif Pro';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 12px;
  max-width: 55%;
  flex: 0;
  opacity: 0;
  animation: ${slideIn} 0.5s ease-out forwards;
  animation-delay: 0s;

  @media only screen and (min-width: 769px) {
    font-size: 48px;
    max-width: 90%;
  }

  @media only screen and (max-height: 430px) {
    font-size: 24px;
    padding-top: 32px;
    margin-bottom: 20px;
  }
`;

const TextContent = styled.div`
  color: ${palette.white};
  font-family: 'Commissioner';
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  max-width: 592px;
  line-height: 150%; /* 36px */
  font-size: 22px;
  flex: 0;
  opacity: 0;
  animation: ${slideInFromBottom} 0.5s ease-out forwards;
  animation-delay: 2s;

  @media only screen and (min-width: 769px) {
    font-size: 24px;
  }

  @media only screen and (max-height: 430px) {
    font-size: 18px;
  }

  p {
    padding: 16px 0 0;

    @media only screen and (max-height: 430px) {
      padding: 8px 0 0;
    }
  }
`;

const BlueBlobContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  bottom: 30%;
  left: 50%;
`;

const MelonBlobContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  bottom: -20%;
  left: 10%;
`;

const LottieContainer = styled.div`
  overflow: hidden;
  position: absolute;
  width: 500px;
  height: 500px;
  bottom: 65%;
  left: 50%;
  z-index: -1;

  @media only screen and (min-width: 380px) and (max-width: 769px) {
    width: 500px;
    height: 500px;
    bottom: 65%;
    left: 54%;
  }

  @media only screen and (min-width: 769px) {
    width: 700px;
    height: 700px;
    bottom: 55%;
    left: 55%;
  }

  @media only screen and (min-width: 1367px) {
    width: 700px;
    height: 700px;
    bottom: 55%;
    left: 55%;
  }

  @media only screen and (max-height: 430px) {
    width: 250px;
    height: 250px;
    top: -38%;
    left: 70%;
  }

  @media only screen and (min-width: 769px) and (max-height: 430px) {
    width: 400px;
    height: 400px;
    top: -35%;
    left: 62%;
  }
`;

const NextBtnWrapper = styled.div`
  min-height: 91px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  animation: ${slideInFromBottom} 0.5s ease-out forwards;
  animation-delay: 2.2s;

  @media only screen and (min-width: 769px) {
    position: unset;
    background-color: transparent;
  }

  @media only screen and (max-height: 430px) {
    min-height: 61px;
  }
`;

const AnalyzingBarContainer = styled.div`
  max-width: 504px;
  margin: auto;
`;

const NextBtnContainer = styled.div`
  height: 81px;
  width: 100%;
  flex: 0;
`;

const AnalyzingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
`;

const AnalyzingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all items */
  justify-content: center; /* Center vertically */
  gap: 12px;
  text-align: center;
  font-family: Commissioner;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  overflow: hidden;

  @media only screen and (min-width: 769px) {
    gap: 36px;
  }
`;

const AnalyzingBar = styled.div`
  width: 345px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 90px;
  background: ${palette.navy700};
  position: relative;
  overflow: hidden;
  z-index: 0;
`;

const Progress = styled.div`
  width: 100%;
  height: 100%;
  background: ${palette.turquoise500};
  border-radius: 90px;
  transform: scaleX(0);
  transform-origin: left;
  animation: progressAnimation 1s ease-out forwards;
  animation-delay: 0s;
  overflow: hidden;
  z-index: 0;

  @keyframes progressAnimation {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

const BlueBackground = styled.div`
  position: fixed;
  z-index: -2;
  top: -50%;
  left: -30%;
  width: 200%;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 200%;
  background-color: ${palette.navy500};
`;

export default connect(null, { pageViewEvent, weCanHelpSubmit })(
  withTracker(WeCanHelp, 'Signup - WeCanHelp - Page View')
);
