/*
 *
 * Definitions on the onboarding several flows
 **/

import { ROUTES } from './routes';

export const FLOW_TYPE = {
  SOCIAL: 'social',
  PAYER: 'payer',
};

export const OnboardingFlows = [
  {
    entryUrl: '/intro',
    isOnboardingFlow: true,
    key: 'onboarding-1',
    steps: [
      { step: 0, routes: [ROUTES.INTRO, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.EMAIL] },
      { step: 2, routes: [ROUTES.GENDER] },
      { step: 3, routes: [ROUTES.BIRTHDATE] },
      { step: 4, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      {
        step: 5,
        routes: [
          ROUTES.HEALTH_PLAN,
          ROUTES.CURRENT_PARTNERS,
          ROUTES.NEW_PARTNER,
        ],
      },
      { step: 6, routes: [ROUTES.FEDERAL_PLAN] },
      { step: 7, routes: [ROUTES.PASSWORD] },
      { step: 8, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: '/intro-2',
    key: 'onboarding-2',
    isOnboardingFlow: true,
    steps: [
      { step: 0, routes: [ROUTES.INTRO_2, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.SELECT_SYMPTOMS] },
      { step: 2, routes: [ROUTES.GI_CONDITIONS, ROUTES.SUCCESS_RATE] },
      { step: 3, routes: [ROUTES.HEALTH_GOALS, ROUTES.WE_CAN_HELP] },
      { step: 4, routes: [ROUTES.EMAIL] },
      { step: 5, routes: [ROUTES.GENDER] },
      { step: 6, routes: [ROUTES.BIRTHDATE] },
      { step: 7, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      {
        step: 8,
        routes: [
          ROUTES.HEALTH_PLAN,
          ROUTES.CURRENT_PARTNERS,
          ROUTES.NEW_PARTNER,
        ],
      },
      { step: 9, routes: [ROUTES.FEDERAL_PLAN] },
      { step: 10, routes: [ROUTES.PASSWORD] },
      { step: 11, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
];
