import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AnimatedRoute from '../../components/AnimatedRoute';
import withTracker from '../../wrappers/withTracker';
import { palette } from '../../theme/palette';
import Lottie from 'lottie-react-web';
import logoLoading from '../../assets/localloader.json';

function Loading({ text }) {
  const [animationDirection, setAnimationDirection] = useState('mount');

  return (
    <PageContainer>
      <AnimatedRoute
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={false}
        hideHeader={true}
        animatedContentStyles={{ justifyContent: 'center' }}
      >
        <Content>
          <LoaderContainer>
            <LoadingAnimation>
              <Lottie
                options={{
                  animationData: logoLoading,
                  loop: true,
                }}
                ariaLabel='Almost there, please wait'
                ariaRole='img'
              />
            </LoadingAnimation>
            <LoaderText>{text}</LoaderText>
          </LoaderContainer>
        </Content>
      </AnimatedRoute>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 100%;
  align-items: center;

  @media only screen and (min-width: 769px) {
    max-width: 600px;
    height: 100%;
    gap: 42px;
    flex: 0;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


const LoaderText = styled.p`
  @media only screen and (min-width: 769px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  align-self: center;
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const LoadingAnimation = styled.div`
  width: 100%;
  max-width: 200px;
`;


export default connect(null, {
})(withTracker(Loading, 'Signup - Hang Tight - Page View'));
