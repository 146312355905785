import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { uiTypes } from '../constants';
import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';
import { getCurrentPageStep } from '../utils/routerHelper';

/**
 * @function useSigninPageUILogic
 * @description Custom hook that tracks URL location changes to calculate user's progress value to
 * complete their sign-up flow
 */
const useSignUpProgress = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  const { isGenericSignupFlow } = useSelector((store) => ({
    isGenericSignupFlow: store.uiReducer?.isGenericSignupFlow,
  }));

  const { key, steps } = useSelector((store) => (store.uiReducer?.onboardingFlow));
  
  React.useEffect(() => {
    dispatch({
      type: uiTypes.TOTAL_PROGRESS_STEPS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenericSignupFlow, key]);

  React.useEffect(() => {
    const list = !!key ? steps : isGenericSignupFlow
    ? genericSignupRouteList
    : regularSignupRouteList;

    const currentStep = getCurrentPageStep(list, location.pathname);
    currentStep >= 0 &&
      dispatch({
        type: uiTypes.CHANGE_PROGRESS,
        payload: currentStep,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isGenericSignupFlow, key]);
};

export default useSignUpProgress;
