/* eslint-disable default-case */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import OshiProgressBar from './OshiProgressBar';
import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';

import { palette } from '../theme/palette';
import {
  HeaderLogoCicrle,
  CirlceLogo,
  HeaderLogo,
  // ArrowRight,
  ArrowLeft,
  MarpaiOshiLogo,
} from '../assets/svgs';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';
import useLink from '../hooks/useLink';
import { getOnboardingRoutes } from '../utils/onboardingFlow';

function OshiHeader({
  type,
  action,
  segment,
  testId,
  logoType = null,
  text,
  style,
  showProgressBar = false,
}) {
  
  const isGenericSignupFlow = useSelector(
    (store) => store.uiReducer?.isGenericSignupFlow
  );

  const { key, isOnboardingFlow } = useSelector(
    (store) => store.uiReducer?.onboardingFlow
  );
  
  const progressBarList = isOnboardingFlow
    ? getOnboardingRoutes(key)
    : isGenericSignupFlow
    ? genericSignupRouteList
    : regularSignupRouteList;

  const backHandler = React.useCallback(() => {
    action();
    if (segment) {
      segment();
    }
  }, [action, segment]);

  const { linkRef } = useLink({ ref: null, onClick: backHandler });

  const getCenteredLogo = React.useCallback(() => {
    if (text) {
      return (
        <Text role='img' aria-label={`${text} Logo`}>
          {text}
        </Text>
      );
    }
    if (logoType === 'circle') {
      return <HeaderLogoCicrle role='img' aria-label='Oshi logo' />;
    }
    if (logoType === 'oshiLogo') {
      return <CirlceLogo role='img' aria-label='Oshi logo' />;
    }
    if (logoType === CURRENT_PARTNERS.MARPAI) {
      return <MarpaiOshiLogo role='img' aria-label='Marpai & Oshi Logo' />;
    }

    return <HeaderLogo role='img' aria-label='Oshi Logo' />;
  }, [text, logoType]);

  return (
    <Container
      showProgressBar={showProgressBar}
      data-testid={testId || ''}
      style={style}
    >
      <NavContainer>
        {type === 'back' && (
          <LeftIconContainer
            role='link'
            ref={linkRef}
            tabIndex={0}
            aria-label='Go Back'
            onClick={backHandler}
          >
            <ArrowLeft color={palette.greyText} />
          </LeftIconContainer>
        )}

        <CenterIconContainer fullflex={Boolean(type) === false}>
          {getCenteredLogo()}
        </CenterIconContainer>
        {/* place this empty container to the right as long a left component is available in order to horizontally center oshi logo */}
        {type ? (
          <RightIconContainer onClick={action}>
            {/* {type === 'back' && (<ArrowRight color={palette.greyText} /> )}*/}
          </RightIconContainer>
        ) : null}
      </NavContainer>
      {showProgressBar ? (
        <OshiProgressBar progressBarList={progressBarList} />
      ) : null}
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  z-index: 1;
  flex: 1;
  width: 100%;
  height: ${(props) => (props.showProgressBar ? '64px' : '58px')};
  position: absolute;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
  margin-bottom: ${(props) => (props.showProgressBar ? '16px' : '0')};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  box-sizing: border-box;
  @media only screen and (min-width: 769px) {
    margin-top: 32px;
  }
`;

const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  height: 58px;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media only screen and (min-width: 769px) {
    padding: 0 20px;
  }
`;

const CenterIconContainer = styled.div`
  display: flex;
  flex: ${({ fullflex }) => (fullflex ? '12' : '10')};
  justify-content: center;
`;

const LeftIconContainer = styled.div`
  display: flex;
  left: 24px; // left button width
  flex: 1;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const RightIconContainer = styled.div`
  display: flex;
  flex: 1;
  right: 24px; // right button width
  align-items: center;
  cursor: pointer;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${palette.navy500};
  font-family: 'Usual', sans-serif;
  font-weight: 600;
`;

export default OshiHeader;
