import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { palette } from '../theme/palette';

import useWindowSize from '../hooks/useWindowSize';
import { MobileHeaderBlob } from '../assets/svgs';

function Heading({
  animationDirection,
  animationRef,
  resetAnimaions,
  title,
  cameAfterBrowserBackButtonClick,
  cameAfterOshiHeaderBackButtonClick,
}) {
  const { screenSize } = useWindowSize();

  function getAnime() {
    let anime;

    if (animationDirection === 'mount') {
      anime = {
        from: {
          opacity: 0,
          transform: cameAfterBrowserBackButtonClick
            ? 'translate3d(-100%,0,0)'
            : 'translate3d(100%,0,0)',
        },
        to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      };
    } else if (animationDirection === 'unmount') {
      anime = {
        from: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        to: {
          opacity: 0,
          transform: cameAfterOshiHeaderBackButtonClick
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
        },
      };
    }

    return anime;
  }

  const anime = getAnime();

  const titleAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    ...anime,
  });

  return (
    <HeadingContainer>
      {screenSize.isMobile && (
        <BlobContainer>
          <MobileHeaderBlob height={'100%'} />
        </BlobContainer>
      )}

      <HeadingContent style={titleAnime}>
        <Title>{title}</Title>
      </HeadingContent>
    </HeadingContainer>
  );
}

const Title = styled.h1`
  color: ${palette.navy500};
  font-family: Source Serif Pro;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 72.8px */

  @media only screen and (max-width: 768px) {
    color: ${palette.white};
    font-family: 'Source Serif Pro', serif;
    line-height: 130%; /* 31.2px */
  }

  @media only screen and (max-height: 740px) {
    font-size: 36px;
  }
  
  @media only screen and (max-width: 375px) {
    font-size: 36px;
  }

  @media only screen and (min-width: 769px) {
    font-size: 56px;
  }

  @media only screen and (max-height: 600px)  {
    font-size: 42px;
  }
`;

const HeadingContent = styled(animated.section)`
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: column;
  background-color: transparent;
  wrap-content: wrap;
  max-width: 542px;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;

  @media only screen and (min-width: 769px) {
    background-color: ${palette.white};
    max-width: 600px;
    justify-content: center;
  }

`;

const HeadingContainer = styled.div`
  margin-top: 0;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px 24px;
  background-color: ${palette.navy500};
  padding-top: 96px;
  box-sizing: border-box;


  @media only screen and (min-width: 769px) {
    background-color: ${palette.white};
    padding: 0;
    margin-top: 0px;
    width: 100%;
  }
`;

const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export default React.memo(Heading);
