export const SYMPTOM_OPTIONS = [
  {
    title: 'Bloating',
    key: 'symptom_bloating',
    isChecked: false,
  },
  {
    title: 'Chronic gut inflammation',
    key: 'symptom_chronic_gut_inflammation',
    isChecked: false,
  },
  {
    title: 'Constipation',
    key: 'symptom_constipation',
    isChecked: false,
  },
  {
    title: 'Diarrhea',
    key: 'symptom_diarrhea',
    isChecked: false,
  },
  {
    title: 'Nausea or vomiting',
    key: 'symptom_nausea_or_vomiting',
    isChecked: false,
  },
  {
    title: 'Reflux or heartburn',
    key: 'symptom_reflux_or_heartburn',
    isChecked: false,
  },
  {
    title: 'Stomach Pain',
    key: 'symptom_stomach_Pain',
    isChecked: false,
  },
  {
    title: 'Other GI symptoms',
    key: 'symptom_other_GI_symptoms',
    isChecked: false,
  },
];

export const HEALTH_GOALS_OPTIONS = [
  {
    title: 'Treat an active flare-up or chronic inflammation',
    key: 'health_goal_treat_flare_up',
    isChecked: false,
  },
  {
    title: 'Reduce bloating, indigestion, or other digestive discomfort',
    key: 'health_goal_reduce_bloating',
    isChecked: false,
  },
  {
    title: 'Manage a chronic GI condition',
    key: 'health_goal_manage_chronic_gi',
    isChecked: false,
  },
  {
    title: 'Get back to eating without symptoms',
    key: 'health_goal_eat_without_symptoms',
    isChecked: false,
  },
  {
    title: 'Feel healthier and enjoy activities without worry',
    key: 'health_goal_feel_healthier',
    isChecked: false,
  },
  {
    title:
      'Improve my sleep, mood, energy, stress-levels and other gut-health factors',
    key: 'health_goal_improve_gut_health_factors',
    isChecked: false,
  },
];
