import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { palette } from '../theme/palette';
import { AetnaLogo, BlueCrossNewLogo, UHCLogo } from '../assets/svgs';

const ICONS = [
  { id: 'aetna', component: <AetnaLogo style={{ width: '79px', height: 'auto' }} /> },
  { id: 'uhc', component: <UHCLogo style={{ width: '72px', height: 'auto' }} /> },
  { id: 'bluecross', component: <BlueCrossNewLogo style={{ width: '100px', height: 'auto' }} /> },
];

function PartnersBanner() {
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1120);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1120);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % ICONS.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <PartnersContainer>
      <PartnersContent isSmallScreen={isSmallScreen}>
        <PartnersItem>We accept</PartnersItem>
        {isSmallScreen ? (
          <AnimatedIcon key={ICONS[currentIconIndex].id}>
            {ICONS[currentIconIndex].component}
          </AnimatedIcon>
        ) : (
            <>
              {ICONS.map((icon) => (
                <PartnersItem key={icon.id}>{icon.component}</PartnersItem>
              ))}
              <PartnersItem>...and many more!</PartnersItem>
            </>
          )}
        
      </PartnersContent>
    </PartnersContainer>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PartnersContainer = styled.div`
  background: ${palette.primaryLightColor};
  width: 100%;
  filter: grayscale(100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 0;
`;

const PartnersContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.isSmallScreen ? '16px' : '32px')};
  flex-wrap: wrap;
  font-family: 'Usual';
  font-size: 16px;
  font-weight: 400;
`;

const PartnersItem = styled.div`
  display: inline-block;
  white-space: nowrap;
  color: ${palette.cadetBlue};
  line-height: 24px;
  text-align: left;
`;

const AnimatedIcon = styled.div`
  animation: ${fadeIn} 1s ease-in-out;
  width: 100px; 
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PartnersBanner;
