import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';
import { uiTypes } from '../constants';
import { localStorageGet } from '../utils/localStorageHelper';
import {
  getOnboardingFlowByKey,
  getOnboardingTotalSteps,
} from '../utils/onboardingFlow';

const initialState = {
  isFetching: false,
  isNameRoute: false,
  showBanner: false,
  currentProgress: 0,
  totalProgressSteps: regularSignupRouteList.length,
  isGenericSignupFlow:
    !localStorageGet('partner')?.partner ||
    localStorageGet('isReferringProvider')?.isReferringProvider,
  onboardingFlow: getOnboardingFlowByKey(
    localStorageGet('onboardingFlowKey') || {}
  ),
  hideRightSidedBanner: false,
};

const getTotalSteps = (state) => {
  let totalSteps = state.isGenericSignupFlow
    ? genericSignupRouteList.length
    : regularSignupRouteList.length;

  if (!!state.onboardingFlow?.isOnboardingFlow) {
    totalSteps = getOnboardingTotalSteps(state.onboardingFlow?.key);
  }
  return totalSteps;
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiTypes.IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case uiTypes.IS_NAME_ROUTE:
      return { ...state, isNameRoute: action.isNameRoute };
    case uiTypes.SHOW_BANNER:
      return { ...state, showBanner: action.showBanner };
    case uiTypes.TOTAL_PROGRESS_STEPS:
      return { ...state, totalProgressSteps: getTotalSteps(state) };
    case uiTypes.HIDE_RIGHT_SIDED_BANNER:
      return { ...state, hideRightSidedBanner: action.hideRightSidedBanner };
    case uiTypes.CHANGE_PROGRESS:
      return {
        ...state,
        currentProgress: action.payload,
      };
    case uiTypes.CHANGE_SIGN_UP_FLOW:
      return {
        ...state,
        isGenericSignupFlow: action.payload,
      };
    case uiTypes.SETUP_ONBOARDING_FLOW:
      return {
        ...state,
        onboardingFlow: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
