import React from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';
import OshiCheckbox from './OshiCheckbox';

/**
 * @function OshiMultiCheckbox
 * @description Allows rendering a group of OshiCheckbox components.
 * @param {React.ReactNode} data - List of items to be rendered.
 * @param {styled.CSSObject} styles - Custom CSS object style to customize this component root element.
 * @returns {React.ReactElement}
 */
const OshiMultiCheckbox = ({ data = [], onChange, styles = {} }) => {
  return (
    <>
      {data.map((item, index) => (
        <GroupContainer
          key={item.key}
          borderColor={item.isChecked ? palette.turquoise200 : palette.tan200}
          backgroundColor={item.isChecked ? palette.turquoise50 : 'none'}
          column={index % 2 === 0 ? 'left' : 'right'}
          style={styles}
        >
          <OshiCheckbox
            id={item.key}
            children={item.title}
            value={item.key}
            checked={item.isChecked}
            onClick={() => onChange(item.key)}
            error={item.error}
            style={{
              flex: 1,
              margin: 0,
              height: '100%',
              boxSizing: 'border-box',
            }}
            labelStyle={{
              justifyContent: 'flex-start',
              fontFamily: 'Commissioner',
              fontSize: '16px',
              lineHeight: '150%'
            }}
          />
        </GroupContainer>
      ))}
    </>
  );
};

const GroupContainer = styled.div`
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  position: relative;
  outline: none;
  margin-bottom: 8px;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
`;

export default OshiMultiCheckbox;
